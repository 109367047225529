function validateEmail(email) {
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,})?$/;
  return emailReg.test( email );
}

function lazyLoadGoogleReviews() {
  const options = {
    rootMargin: '0px',
    threshold: 1.0
  };
  const target = document.querySelector('#load-google-reviews');

  const callback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const script = document.createElement('script');
        script.src = "https://static.elfsight.com/platform/platform.js";
        document.head.appendChild(script);
        observer.unobserve(entry.target);
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  observer.observe(target);
}


function nextWorkingDay(auto_approve) {
  var d = new Date()
  var currentHour = d.getHours()
  if (auto_approve == true) {
    return d
  } else {
    if (d.getDay() == 5 && currentHour >= 16) {
      d.setDate(d.getDate()+4)
    } else if (d.getDay() == 6) {
      d.setDate(d.getDate()+3)
    } else if (d.getDay() == 0) {
      d.setDate(d.getDate()+2)
    } else if (currentHour >= 16) {
      d.setDate(d.getDate()+2)
    } else {
      d.setDate(d.getDate()+1); // tomorrow
    }
    return d;
  }
}

function truncateString(string, stringLength = 10) {
  if (string.length > stringLength) {
    return `${string.substring(0, stringLength)}...`;
  } else {
    return string
  }
}

function checkUserIsAdmin() {
  var response = $.ajax({
    type: "GET",
    async: false,
    dataType: 'JSON',
    url: "/users/check_user_is_admin"
  }).responseJSON;
  return response['user_is_admin']
}


function setRequirementsSubmittedWithExpiry() {
  var currentDate = new Date();
  var expiry = currentDate.setDate(currentDate.getDate() + 1)

  var item = {
    value: true,
    expiry: expiry
  }

  localStorage.setItem('officeRequirementsSubmitted', JSON.stringify(item));
}

function getRequirementsSubmittedWithExpiry() {
  var currentDate = new Date();
  var now = currentDate.setDate(currentDate.getDate())
  var requirementsSubmittedString = localStorage.getItem('officeRequirementsSubmitted')
  var officeRequirementsSubmitted = JSON.parse(requirementsSubmittedString)
  if (officeRequirementsSubmitted) {
    if (officeRequirementsSubmitted.value == true && officeRequirementsSubmitted.expiry > now) {
      return true
    } 
  } else {
    return false
  }
}

function loadGoogleReviews() {
  const script = document.createElement('script');
  script.src = "https://apps.elfsight.com/p/platform.js";
  script.async = true;
  document.head.appendChild(script);
}

export { validateEmail, lazyLoadGoogleReviews, nextWorkingDay, truncateString, checkUserIsAdmin, setRequirementsSubmittedWithExpiry, getRequirementsSubmittedWithExpiry, loadGoogleReviews}