function toggleTokenPurchaseOption(totalTokens, availableTokensToUser, teamTokens, currentUserIsAdmin, currentUserCannotPayg, RequireApprovalForCollabSpace) {
  if (currentUserIsAdmin) return;
  if (RequireApprovalForCollabSpace && totalTokens <= teamTokens) {
    $('#meeting-room-request-explainer').removeClass('hidden')
    $('#payg-restricted-for-user').addClass('hidden')
    $('#submit-section').removeClass('hidden')
    canSubmit = true
    enableSubmit()
    return;
  }
  if ((totalTokens > availableTokensToUser) && currentUserCannotPayg) {
    $('#payg-restricted-for-user').removeClass('hidden')
    $('#booking-summary').addClass('hidden')
    $('#submit-section').addClass('hidden')
  } else {
    $('#payg-restricted-for-user').addClass('hidden')
    $('#booking-summary').removeClass('hidden')
    $('#submit-section').removeClass('hidden')

  }
}

function updateHoursDropdown(cost_increments, input) {
  var day = $('<option></option>').attr("value", 'full_day').text("Full day (open › close)");
  var half_day = $('<option></option>').attr("value", 'half_day').text("Half day (4 hours)");
  var month =  $('<option></option>').attr("value", 'month').text("1 Month Pass");
  var hours = [["1 hour", 1], ["2 hours", 2], ["3 hours", 3], ["4 hours", 4], ["5 hours", 5], ["6 hours", 6], ["7 hours", 7], ["8 hours", 8], ["9 hours", 9], ["10 hours", 10], ["11 hours", 11], ["12 hours", 12]]
  $(`#${input}`).empty()
  if (cost_increments.includes("cost_per_day")) {
    $(`#${input}`).append(day)
  }
  if (cost_increments.includes("cost_per_half_day")) {
    $(`#${input}`).append(half_day)
  }
  if (cost_increments.includes("cost_per_hour")) {
    $.each(hours, function (i, hour) {
      $(`#${input}`).append($('<option></option>').attr("value", hour[1]).text(hour[0]));
    });
  }
  if (cost_increments.includes("cost_per_month")) {
    $(`#${input}`).append(month)
  }
}

function toTitleCase(str) {
  return str.replace('_',' ').toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

function updateArrivalTimes() {
  var date = window.location.href.includes("booking_steps") ? new Date($('#booking_steps_detail_date').val()) : new Date($('#select-date').val());
  var $arrival_times_field = window.location.href.includes("booking_steps") ? $('#booking_steps_detail_arrival_time') : $('#arrival_time')
  if ( openingHours[date.getDay()].indexOf("Closed") >= 0 ) {
    $arrival_times_field.empty();
    $arrival_times_field.append( $('<option></option>').val(1).html("Closed") ).attr('disabled', 'disabled');
  } else {
    var quarterHours = ["00", "30"];
    var times = [];
    for(var i = 0; i < 24; i++){
      for(var j = 0; j < 2; j++){
        times.push(i + ":" + quarterHours[j]);
      }
    }

    var a = times.indexOf(openingHours[date.getDay()].substr(0,5).replace(/^0+/, ''));
    var b = times.indexOf(openingHours[date.getDay()].substr(6,11).replace(/^0+/, ''));

    times.length = b+1;
    times.splice(0, a);

    $arrival_times_field.empty().attr('disabled', false).removeClass('text-gray-200').addClass('text-black-800')

    $.each(times, function(index, text) {
      $arrival_times_field.append( $('<option></option>').val(text).html(text) );
    });
  }
}

export { toggleTokenPurchaseOption, updateHoursDropdown, toTitleCase, updateArrivalTimes }