import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "label", "checkbox", "toggle"]
  static values = { capacityChecked: false }

  connect() {
    const setLoaderVisibility = (opacity, hidden) => {
      $('.coworking-filters-and-results').css('opacity', opacity);
      $('.loading-spinner').toggleClass('hidden', hidden);
    }

    this.formTarget.addEventListener("ajax:beforeSend", () => {
      setLoaderVisibility(0.5, false)
    })

    this.formTarget.addEventListener("ajax:complete", () => {
      setLoaderVisibility(1, true)
      $('#autocomplete-results').hide()
    })
  }

  selectOption(event) {
    var thisButtonClasses = event.target.closest('.filter-button').classList
    var isActive = thisButtonClasses.contains("active")
    if (event.target.tagName != "INPUT") {
      return
    } else {
      if (!isActive) {
        thisButtonClasses.remove('border')
        thisButtonClasses.add("active", "font-extrabold", "bg-blue-100", "border-2")
      } else {
        thisButtonClasses.remove("active", "font-extrabold", "bg-blue-100", "border-2")
        thisButtonClasses.add('border')
      }
    }
  }

  toggleFilter(event) {
    var filtersCount = parseInt($('#filters-count').text())
    if (event.target.value === 'false' || event.target.value === '') {
      event.target.value = true;
      $('#filters-count').removeClass('hidden')
      $('#filters-count').addClass("ml-1 py-0.5 px-2")
      $('#filters-count').text(filtersCount + 1)
    } else {
      event.target.value = false
      $('#filters-count').text(filtersCount - 1)
    }
    if ($('#filters-count').text() === '0') {
      $('#filters-count').addClass('hidden')
    }
    this.submit(event)
  }

  clearFilters(event) {
    this.labelTargets.forEach((label) => {
      label.classList.remove("font-extrabold")
      label.classList.remove("border-2")
      label.classList.remove("bg-blue-100")
      label.classList.remove("active")
      label.classList.add("border")
    })
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.toggleTargets.forEach((toggle) => {
      toggle.value = false
      toggle.classList.add('bg-gray-200')
    })
    $('#filtered-results-text').text('')
    $('.filter-options').addClass('hidden');
    $('#filters-count').addClass('hidden')
    $('.min-capacity-input').val('')
    $('.max-capacity-input').val('')
    $('#filters-count').text('0')
    $('.clear-filters').addClass('hidden')
    this.submit(event)
  }

  search(event) {
    $('#location-field').val("");
    this.submit(event)
  }

  submit(event) {
    var filtersCount = parseInt($('#filters-count').text())
    if ($('.min-capacity-input').val() > 1 || $('.max-capacity-input').val() != '') {
      $('#clear-filters').removeClass('hidden')
    }
    if (event.type === 'input' && event.target.name != 'categories[]') {
      if (event.target.checked === true) {
        $('#filters-count').removeClass('hidden')
        $('#filters-count').addClass("ml-1 py-0.5 px-2")
        $('#filters-count').text(filtersCount + 1)
      } else {
        $('#filters-count').text(filtersCount - 1)
      }
    }
    if ((filtersCount === 0) && (($('.min-capacity-input').val() === '') && ($('.max-capacity-input').val() === ''))) {
      $('#filters-count').addClass('hidden')
      $('#clear-filters').addClass('hidden')
    } else {
      $('#clear-filters').removeClass('hidden')
    }

    $('#autocomplete-results').hide();
    this.formTarget.requestSubmit()
  }
}