import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.loaded = false;
  }

  load() {
    if (this.loaded) return;

    const url = this.data.get("url");
    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.containerTarget.innerHTML = html;
        this.loaded = true;
      })
      .catch(error => {
        console.error("Failed to load office spaces:", error);
      });
  }
}
