import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      filter: ".no-sort",
      onStart: this.start.bind(this),
      onEnd: this.end.bind(this),
      onMove: this.move.bind(this), 
    })
  }

  start(event) {
    var venueCards = event.item.querySelectorAll('.venue-card')
    venueCards.forEach((card) => {
      card.classList.add('bg-yellow-50')
    })
  }

  end(event) {
    var venueCards = event.item.querySelectorAll('.venue-card')
    venueCards.forEach((card) => {
      card.classList.remove('bg-yellow-50')
    })

    var officeCardElements = document.querySelectorAll('.office-card');
    var viewingCardElements = document.querySelectorAll('.viewing-card');

    if (officeCardElements.length > 0) {
      var venueIds = [];

      officeCardElements.forEach((element) => {
        var venueId = element.getAttribute('data-venue-id');
        if (venueId) {
          venueIds.push(venueId);
        }
      });

      let id = event.item.dataset.officeSearchId
      let data = new FormData()
      venueIds.forEach((venueId) => {
        data.append("venue_ids[]", venueId);
      });

      $.ajax({
        url: `/office_searches/${id}/order_venues`,
        type: 'PATCH',
        data: data,
        processData: false,
        contentType: false,
      })
    } else if (viewingCardElements.length > 0) {

      let viewingScheduleId = event.item.dataset.viewingScheduleId
      let viewingId = event.item.dataset.viewingId
      let data = new FormData()
      let oldIndex = (event.oldIndex)
      let newIndex = (event.newIndex)
      data.append("viewing_id", viewingId)
      data.append("old_order", oldIndex)
      data.append("new_order", newIndex)
      
      $.ajax({
        url: `/viewing_schedules/${viewingScheduleId}/order_venues`,
        type: 'PATCH',
        data: data,
        processData: false,
        contentType: false,
      })
    }
  }

  move(event) {
    // Prevent moving elements with the 'no-sort' class
    if (event.related.classList.contains('no-sort')) {
      return false;
    }
  }
}