// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap-datepicker")
require("packs/booking_form")

import '../stylesheets/application.scss'
import { toggleTokenPurchaseOption, updateHoursDropdown, toTitleCase, updateArrivalTimes } from './booking_form'
window.toggleTokenPurchaseOption = toggleTokenPurchaseOption
window.updateHoursDropdown = updateHoursDropdown
window.toTitleCase = toTitleCase
window.updateArrivalTimes = updateArrivalTimes


import { getShortlistedVenues } from './office_shortlisting'
window.getShortlistedVenues = getShortlistedVenues

import { validateEmail, lazyLoadGoogleReviews, nextWorkingDay, truncateString, checkUserIsAdmin, setRequirementsSubmittedWithExpiry, getRequirementsSubmittedWithExpiry, loadGoogleReviews } from './global'
window.validateEmail = validateEmail
window.lazyLoadGoogleReviews = lazyLoadGoogleReviews
window.nextWorkingDay = nextWorkingDay
window.truncateString = truncateString
window.checkUserIsAdmin = checkUserIsAdmin
window.setRequirementsSubmittedWithExpiry = setRequirementsSubmittedWithExpiry
window.getRequirementsSubmittedWithExpiry = getRequirementsSubmittedWithExpiry
window.loadGoogleReviews = loadGoogleReviews

import './search_page';
import { initMap } from './map'
window.initMap = initMap
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
