function getShortlistedVenues() {
  if (document.cookie) {
    var cookies = document.cookie.split("; ");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].split("=");
      if (cookie[0] == 'shortlisted_venue_ids') {
        var venue_ids = getVenuesFromCookie(cookie)
        return JSON.parse(venue_ids);
      }
    }
  }
return "";
}

function getVenuesFromCookie(cookie) {
  if (cookie[1].includes('&')) {
    return `[${cookie[1].split("&").join(", ")}]`
  } else {
    return cookie[1]
  }
}

export { getShortlistedVenues }