import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "checkbox"]

  connect() {
    const setLoaderVisibility = (opacity, hidden) => {
      $('.fto-filters-and-results').css('opacity', opacity);
      $('.loading-spinner').toggleClass('hidden', hidden);
    }

    this.formTarget.addEventListener("ajax:beforeSend", () => {
      setLoaderVisibility(0.5, false)
    })

    this.formTarget.addEventListener("ajax:complete", () => {
      setLoaderVisibility(1, true)
      $('#autocomplete-results').hide()
    })
  }

  selectOption(event) {
    event.target.classList.toggle("font-bold")
    event.target.classList.toggle("border")
    event.target.classList.toggle("border-2")
    event.target.classList.toggle("bg-blue-100")
  }

  checkFiltersApplied() {
    var checkedAmenities = []
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        checkedAmenities.push(checkbox)
      }
    })
    if (checkedAmenities.length > 0 || $('#fto-search-autocomplete').val() != '' || $('#capacity-input').val() != '' || $('#price-input').val() != '') {
      $('#clear-filters').removeClass('hidden')
    }
  }

  clearAllFilters() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    $('#fto-search-autocomplete').val('')
    $('#min-capacity-input').val('')
    $('#max-capacity-input').val('')
    $('#min-meeting-rooms-input').val('')
    $('#max-meeting-rooms-input').val('')
    $('#capacity-input').val('')
    $('#min-price-input').val('')
    $('#max-price-input').val('')
    $('#price-input').val('')
    $('#budget-input').val('')
    $('#number_of_desks').val('')
    $('#location-field').val('')
    $('#clear-filters').addClass('hidden')
    this.submit()
  }

  clearAmenitiesFilters() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.submit()
  }

  submit() {
    $('#autocomplete-results').hide()
    this.checkFiltersApplied()
    this.formTarget.requestSubmit()
  }

}