import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["map"];

  connect() {
    this.mapTarget.addEventListener("googleMap:reinitialized", this.setupMapListeners.bind(this));
    this.setupMapListeners();
    document.querySelector("#search-here-button").addEventListener("click", this.searchHere.bind(this));
  }

  setupMapListeners() {
    if (window.googleMap) {
      this.isInitialLoad = true;
      const zoomLevel = window.googleMap.getZoom()

      window.googleMap.addListener("drag", () => {
        this.isInitialLoad = false;
        if (!this.isUpdatingOnMapMove()) {
          document.querySelector(".update-moving-map-container").classList.add("hidden")
          document.querySelector(".search-here-container").classList.remove("hidden")
        }
      });

      window.googleMap.addListener('idle', () => {
        if (!this.isInitialLoad && this.isUpdatingOnMapMove()) {
          this.updateCoordinatesAndSubmit();
        }
      });

      window.googleMap.addListener("zoom_changed", () => {
        // This logic will increase / decrease the search radius based on user zooming in / out if selected to update map on move
        if (this.isUpdatingOnMapMove()) {
          const radiusInput = document.querySelector(".fto-radius-dropdown") || document.querySelector(".radius-dropdown");
          const newZoom = window.googleMap.getZoom()
          if (radiusInput) {
            var one = "1" || 1
            if (newZoom > 14 && radiusInput.value != one) {
              var newRadius = 1
            } else if (newZoom > zoomLevel) {
              var newRadius = parseInt(radiusInput.value) * 0.5
              if (newRadius < 1) return;
            } else if (newZoom < zoomLevel && newZoom < 14) {
              var newRadius = parseInt(radiusInput.value) * 2
              if (newRadius > 512) return;
            }
            else {
              return;
            }
            radiusInput.value = newRadius
            this.updateCoordinatesAndSubmit()
          }
        }
      });
    }
  }

  isUpdatingOnMapMove() {
    const checkbox = document.querySelector("#update-moving-map");
    return checkbox && checkbox.checked;
  }

  searchHere() {
    this.updateCoordinatesAndSubmit();
    document.querySelector(".update-moving-map-container").classList.remove("hidden")
    document.querySelector(".search-here-container").classList.add("hidden")
  }

  updateCoordinatesAndSubmit() {
    const center = window.googleMap.getCenter();
    const zoom = window.googleMap.getZoom()
    const lat = center.lat();
    const lng = center.lng();

    const formElement = document.querySelector("[data-controller='fto-filters']") || document.querySelector("[data-controller='filters']");
    const coordinatesInput = formElement?.querySelector("#coordinates");
    const zoomInput = formElement?.querySelector("#zoom");

    if (coordinatesInput) {
      coordinatesInput.value = `${lat}, ${lng}`;
    }

    if (zoomInput) {
      zoomInput.value = zoom;
    }

    if (formElement) {
      formElement.requestSubmit();
      coordinatesInput.value = null
      document.querySelector(".location-text").textContent = 'Map area'
    }
  }
}
